<template lang="pug" >
.list-page(:class="[lang]")
  header.header
    .content
      .logo_area(@click="backHome")
        .img_area
          img.logo(src="../assets/img/logo.png")
        .title Answered!
      .LanSwitch
        .input-area
          input.input(:placeholder="$TT('search')" maxlength='150' v-model='inputValue' @keyup.enter="search('listpage_search_enter')")
          img.input-search_icon(src="../assets/img/m/input-icon.png")
        el-dropdown(@command="handleCommand")
          span.el-dropdown-link
            | {{command}}
            i.el-icon-arrow-down.el-icon--right
          el-dropdown-menu(slot="dropdown")
            el-dropdown-item(command="Eng") Eng
            el-dropdown-item(command="Indonesia") Indonesia
        .google_img(@click="download('listpage_download_head_click')")
          .google
            img(src="../assets/img/google.png")
  section.section
    .content
      .part-left
        .problems-list
          .title(v-html="$TT('problems')")
          .cut-off-line
          .no-problems(v-if="isNoProblems")
            .no-porblems_img
              img(src="../assets/img/noProblems.png")
            .no-problems_text(v-html="$TT('noProblems')")
          .scroll(v-infinite-scroll="getOnQuestionList" :infinite-scroll-disabled="loading" infinite-scroll-distance="10")
            .problems-content#problems
              .problems-item(v-for='(item, index) in questionList' :key='index')
                .item_detail(v-html='item.question')
                .check-solution(v-html="$TT('Check solution')" @click="viewDetails(item, 'listpage_checksolution_click')")
              .bottom#bottom
        .loading(v-if='loading')
          img(src="../assets/img/loading.gif")
        .try-again(v-if="isLoadingFailed")
          .title(v-html="$TT('tryagain')")
          .button-try_again(v-html="$TT('button-try_again')" @click="tryAgain('listpage_tryagain')")
      .try-more
        .title(v-html="$TT('tryMore')")
        .desc
          .item
            img.img(src='../assets/img/point.png')
            span(v-html="$TT('descItem-one')")
          .item
            img.img(src='../assets/img/point.png')
            span(v-html="$TT('descItem-two')")
          .item
            img.img(src='../assets/img/point.png')
            span(v-html="$TT('descItem-three')")
          .item
            img.img(src='../assets/img/point.png')
            span(v-html="$TT('descItem-four')")
        .google_img(@click="download('listpage_download_side_click')")
          .google
            img(src="../assets/img/google.png")
</template>
<script>
import service from '@/service/index.js'
import MathJax from '@/util/mathJax.js'
import { mapState } from "vuex";

export default {
  metaInfo() {
    return {
      title: 'Answered!',
    }
  },
  data() {
    return {
      lang: 'en',
      command: 'Eng',
      downloadUrl: 'https://answered.onelink.me/DJB9/a15710c3',
      isLoadingFailed: false,
      inputValue: '',
      size: 15,
      tag: '',
      questionList: [],
      scrollTop: 0,
      timerId: null,
      timeId: null,
      searchTop: 0,
      isNoProblems: false,
    }
  },
  computed:{
    ...mapState([
      'loading'
    ])
   },
  created() {
    this.lang = this.$route.query.lang || 'en'
    this.$setLang(this.lang)
    window._rlog.push(['_trackCustom', 'EVENT', [['lang', this.lang]]])
    window.gtag('event', 'listpage_show')
  },
  mounted() {
    this.inputValue = this.$route.query.inputValue || ''
    this.getQuestionList()
  },
  beforeRouteUpdate (to, from, next) {
    if(to.fullPath === from.fullPath) {
      next()
    } else {
      this.inputValue = to.query.inputValue
      this.lang = to.query.lang
      this.$setLang(this.lang)
      this.getQuestionList()
    }
    next()
  },
  watch: {
    command:function(){
      if(this.command == 'Eng' && this.lang !== 'en'){
        this.lang = 'en'
        this.$setLang(this.lang)
        this.search('listpage_search_enter')
      }else if (this.command == "Indonesia" && this.lang !== 'id'){
        this.lang = 'id'
        this.$setLang(this.lang)
        this.search('listpage_search_enter')
      }
    },
    lang:function(){
      window._rlog.push(['_trackCustom', 'EVENT', [['lang', this.lang]]])
      window.gtag('event', 'language_switch')
      if(this.lang == 'en'){
        this.command = 'Eng'
      }else if (this.lang == 'id'){
        this.command = "Indonesia"
      }
    }
  },
  methods: {
    backHome() {
      this.$router.push({
        path: '/',
        query: {
          lang: this.lang
        }
      })
    },
    formatMath() {
      let that = this;
      if(this.timeId) {
        clearTimeout(this.timeId);
      }
      this.timeId = setTimeout(function () {
        that.$nextTick(function () {
          if(MathJax.initMathjaxConfig()){//判断是否初始配置，若无则配置。
            MathJax.MathQueue('problems');
          }
        })
      },10);
    },
    download(eventType) {
      window.gtag('event', eventType)
      const a = document.createElement('a')
      a.href = this.downloadUrl
      a.click()
    },
    handleCommand(command){
      this.command = command
    },
    viewDetails(item, eventType) {
      window.gtag('event', eventType)
      this.$router.push({
        path: '/quesDetails',
        query: {
          lang: this.lang,
          id: item.id
        }
      })
    },
    search(eventType) {
      window.gtag('event', eventType)
      this.$router.push({
        path: '/quesList',
        query: {
          lang: this.lang,
          inputValue: this.inputValue
        }
      })
    },
    tryAgain(eventType) {
      window.gtag('event', eventType)
      if(this.tag === '') {
        this.getQuestionList()
      } else {
        this.getOnQuestionList()
      }
    },
    getOnQuestionList() {
      if(this.loading) {
        return
      }
      this.$store.commit('showLoading')
      const formData = new FormData()
      formData.append('text', this.inputValue)
      formData.append('country', 'ID')
      formData.append('size', this.size)
      formData.append('tag', this.tag)
      service.searchQuestion(formData).then((res) => {
        this.isLoadingFailed = false
        this.$store.commit('hideLoading')
        this.questionList.push(...res.data.rows)
        this.tag = res.data.tag
        this.formatMath();
      }).catch((err) => {
        this.isLoadingFailed = true
        this.$store.commit('hideLoading')
      })
    },
    getQuestionList() {
      if(this.loading) {
        return
      }
      this.$store.commit('showLoading')
      this.tag = ''
      const formData = new FormData()
      formData.append('text', this.inputValue)
      formData.append('country', 'ID')
      formData.append('size', this.size)
      formData.append('tag', this.tag)
      service.searchQuestion(formData).then((res) => {
        this.isLoadingFailed = false
        this.$store.commit('hideLoading')
        if(res.data.rows.length === 0) {
          this.isNoProblems = true
        } else {
          this.isNoProblems = false
        }
        this.questionList = res.data.rows
        this.tag = res.data.tag
        this.formatMath();
      }).catch((err) => {
        this.isLoadingFailed = true
        this.$store.commit('hideLoading')
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.list-page
  font-family Roboto
.header
  display flex
  align-items center
  top 0
  height 128px
  margin 0 auto
  align-items center
  z-index 999
  width 100%
  .content
    width 1157px
    margin 0px auto
    position relative
    display flex
    align-items center
    .logo_area
      display flex
      align-items center
      cursor pointer
      .img_area
        width 48px
        height 48px
        display inline-block
        img
          width 100%
      .title
        font-family:Roboto-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: #111111;
        display inline-block
        margin-left 15px
    .LanSwitch
      position absolute
      right 0
      .input-area
        display inline-block
        vertical-align middle
        position relative
        margin-right 24px
        .input
          width 351px
          height 56px
          border-radius 8px
          background #F3F3F3
          font-family Roboto-Regular
          font-size 16px
          line-height 56px
          color #A0A0A0
          outline none
          padding-left 49px
        .input-search_icon
          width 20px
          height 20px
          position absolute
          top 18px
          left 20.25px
      .el-dropdown
        display inline-block
        vertical-align middle
        span
          font-family Roboto
      .google_img
        width 189px
        height 56px
        cursor pointer
        display inline-block
        vertical-align middle
        margin-left 24px
        img
          width 100%
.section
  background  #F8FAFD
  overflow hidden
  min-height calc(100vh - 128px)
  .content
    width 1157px
    margin 60px auto 0
    justify-content space-between
    display flex
    .part-left
      .loading
        width 697px
        height 100px
        background #FFFFFF
        img
          margin-left calc(50% - 70px)
          width 140px
          height 100px
      .try-again
        width 697px
        height 230px
        background #FFFFFF
        overflow hidden
        margin-bottom 147px
        cursor pointer
        .title
          width 617px
          height 33px
          margin 62px auto 24px
          text-align center
          font-family Roboto-Bold
          font-size 28px
          line-height 33px
          color #333333
        .button-try_again
          width 135px
          height 48px
          margin 0 auto 63px
          background #FFFFFF
          border 2px solid #FF6B08
          border-radius 8px
          font-weight 700
          font-size 20px
          line-height 48px
          text-align center
          color #FF6B08
      .problems-list
        width 697px
        overflow hidden
        background #FFFFFF
        box-shadow 0px 3px 30px rgba(132, 145, 165, 0.15)
        border-radius 8px
        .no-problems
          .no-porblems_img
            margin 80px auto 32px
            width 140px
            height 106px
            img
              width 100%
           .no-problems_text
            width 200px
            margin 0px auto 90px
            font-family Roboto-Regular
            font-size 18px
            line-height 27px
            letter-spacing 0.1px
            color #111100
            text-align center
        .title
          height 44px
          margin 30px 0 30px 40px
          font-family Roboto-Black
          font-size 32px
          line-height 44px
          color #111100
        .cut-off-line
          width 100%
          height 1px
          background #DDDDDD
        .problems-content
          overflow hidden
          .problems-item
            margin 40px auto 0
            width 617px
            border-bottom 1px solid #E8E8E8
            .item_detail
              font-size 16px
              line-height 19px
              color #333333
            .check-solution
              width 193px
              height 48px
              background linear-gradient(0deg, rgba(255, 107, 8, 0.1), rgba(255, 107, 8, 0.1)), #FFFFFF
              border-radius 8px
              font-size 20px
              line-height 48px
              color #FF6B08
              text-align center
              font-weight 500
              margin 32px 0 40px
              cursor pointer
    .try-more
      width 399px
      height 420px
      background linear-gradient(139.74deg, #FFF5ED -12.64%, #FFFFFF 75.01%)
      box-shadow 0px 3px 30px rgba(132, 145, 165, 0.15)
      border-radius 8px
      .title
        margin 60px 0 20px 53px
        font-family:Roboto-Bold;
        font-size 32px
        line-height 37px
        color #333333
      .desc
        margin 0 0 36px 53px
        .item
          margin-bottom 16px
          font-family Roboto-Regular
          font-size 14px
          line-height 16px
          color #666666
          .img
            width 10px
            height 10px
            display inline-block
            margin-right 8px
      .google_img
        width 189px
        height 56px
        margin-left 53px
        cursor pointer
        img
          width 100%
.el-dropdown-link
  font-family: SourceHanSansSC-Medium;
  font-size: 16px;
  line-height: 24px;
  color: #1E1E20;
  cursor: pointer;
</style>
<style lang="stylus">
.icon
  width 10px
  height 10px
  box-sizing border-box
  display inline-block
  background linear-gradient(0deg, rgba(255, 107, 8, 0.1), rgba(255, 107, 8, 0.1)), #FFFFFF
  border 2px solid #FF6B08
  border-top 0px
  border-left 0px
  transform rotate(-45deg)
</style>
